import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://player.vimeo.com/video/440038323"
    bibleGroupSrc="https://www.docdroid.net/JqRlGly/bible-group-homework-7-19-pdf"
    bibleGroupText="Bible Group Homework"
  >
    <SEO title="Isaiah - Now What?" />
  </Layout>
)

export default SermonPost
